import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

import bg from "../assets/images/ContentImageHandler47b2.jpg"
import mobile from "../assets/images/mobile.jpeg"
import hidden from "../assets/images/hidden.png"
import hiddens from "../assets/images/piggy-bank.png"
import hidden3 from "../assets/images/mobile-banking.png"
import file from "../assets/images/file.png"
import money from "../assets/images/money.png"
import secure from "../assets/images/life-insurance.png"
const Checking = () => {
  return (
    <div>
      {/* Hero Section */}

      
      <section className="hero-sections1 py-5 text-center bg-primary text-white">
        <Container className='mt-4'>
        <div className='bgs'>
          <p className='h1 gn-title '>Checking Account</p>
          <p className="card-text">
          We have several different personal checking options designed to meet your daily banking needs.

In addition, we offer electronic bill payment in all of our checking accounts as well as convenient 24-hour account access through our secure online, mobile, and telephone banking systems.

GN Bank is FDIC insured for up to $250,000.
          </p>
          <Button   className='btn bg-checking'>
            Open an Account
          </Button>
          </div>
        </Container>
      </section>
      
      {/* Benefits Section */}
      <section className="benefits-section py-5">
        <Container>
          <h2 className="text-center h1 mb-4 gn-title">Key Benefits?</h2>
          <Row>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                <Card.Img variant="top" src={hidden}  />
                  <Card.Title className='gn-title h5'>No Monthly Fees</Card.Title>
                  <Card.Text>
                    Enjoy a hassle-free banking experience with no monthly maintenance fees.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                <Card.Img variant="top" src={hidden3}  />
                  <Card.Title className='gn-title'>FDIC Insured</Card.Title>
                  <Card.Text>
                    Federally Insured up to $250,000.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
            
                <Card.Body>
                
                <Card.Img variant="top" src={hidden3}  />
                  <Card.Title className='gn-title'>Mobile Banking</Card.Title>
                  <Card.Text>
                    Manage your account anytime, anywhere with our mobile app.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section  py-5 bg-light">
        <Container className='py-5'>
          <h2 className="text-center mb-4 h1 gn-title">Key Features ?</h2>
          <Row className='text-justify gn-row'>
            <Col md={4}>
            <Card className="h-100 shadow-sm">
                <Card.Body>
                <Card.Img variant="top" src={file}  />
                  <Card.Title className='gn-title'>Free Online Bill Pay</Card.Title>
                  <Card.Text>
                    Pay Bill Directly using our website or mobile app
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>
            <Col md={4}>

            <Card className="h-100 shadow-sm">
                <Card.Body>
                <Card.Img variant="top" src={money}  />
                  <Card.Title className='gn-title'>Secure Online Access</Card.Title>
                  <Card.Text>
                    Pay Bill Directly using our website or mobile app
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>

            <Col md={4}>

            <Card className="h-100 shadow-sm">
                <Card.Body>
                <Card.Img variant="top" src={secure}  />
                  <Card.Title className='gn-title'>FDIC Insured</Card.Title>
                  <Card.Text>
                    Up to 250,000
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>
          </Row>





      



        </Container>
      </section>

      <Container>



      <Row className='mb-3 py-5'>







<Col md={6}>
<div className='bg-texts'>

<h2 className='gn-middle-text h2 mt-5 gn-title'>GET STARTED HERE</h2>
<p className='card-text gn-secondary-title'>Access Your Account From Anywhere </p>
<p className='card-text'>Online banking can save you a lot of time and effort, you can undertake most transactions from the comforts of your home. However, 
it is crucial to use internet banking safely.</p>


<Button className="gn-button">Learn More</Button>

</div>

</Col>



<Col md={6}>

<img src={mobile} width="100%" height="356px"/>

</Col>
</Row>


      </Container>

      {/* Call to Action Section */}
      <section className="cta-section py-5 text-center bg-darks text-white">
        <Container>
          <h2 className='h2'>Ready to Open Your Account?</h2>
          <p className="lead text-center card-text text-white">
            Start your journey to better banking today.
          </p>

          <div className='gn-button-div'>
          <Button  size="lg" className='gn-button'>
            Open an Account
          </Button>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Checking;
