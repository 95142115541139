import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const LoginModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    accountType: 'Personal', // Default selection
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endpoint =
      formData.accountType === 'Personal'
        ? ' https://web10.secureinternetbank.com/PBI_PBI1151/Login/271070924'

       
      
        : 'https://web10.secureinternetbank.com/EBC_EBC1151/ForgotPassword/271070924/1';

    try {
      const response = await axios.post(endpoint, {
        username: formData.username,
        password: formData.password,
      });
      alert('Login Successful!');
      console.log(response.data);
    } catch (error) {
      alert('Login Failed. Please check your credentials.');
      console.error(error);
    }
  };

  return (

    <div className='container py-3 mr-3'>

   
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>


               {/* Account Type Dropdown */}
          <Form.Group className="mb-3" controlId="formAccountType">
            <Form.Label>Account Type</Form.Label>
            <Form.Select
              name="accountType"
              value={formData.accountType}
              onChange={handleInputChange}
              required
            >
              <option value="Personal">Personal</option>
              <option value="Business">Business</option>
            </Form.Select>
          </Form.Group>
          {/* Username Field */}
          <Form.Group className="mb-3" controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          {/* Password Field */}
          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

       

          {/* Submit Button */}

          <div className='modal-buttons'>
          <Button variant="primary" type="submit" className="w-100 bg-buttons">
            Login
          </Button>
          <Button variant="primary" type="submit" className="w-100 bg-buttons mr-2">
          Enroll
          </Button>

          </div>
        </Form>
      </Modal.Body>
    </Modal>
    </div>
  );
};

export default LoginModal;
