import React from 'react'
import { Container, Col,Row, ListGroup, Nav } from 'react-bootstrap'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  'react-social-icons/facebook'
import 'react-social-icons/instagram'
import 'react-social-icons/youtube'

import { SocialIcon } from 'react-social-icons'

import play from '../../assets/img/slides/google.jpg'

import app from '../../assets/img/slides/apple.jpg'
import { Link } from 'react-router-dom'

import fdic from "../../assets/images/fdic-logo.png"
import GNBankCardGrid from '../GNBankCardGrid'

const Footer = () => {
  return (
    <>

<div className='bottom-dark'>

<GNBankCardGrid/>

  
</div>

    <Container fluid={true} className="footer-bg py-5">

      <div className='bottom-footer'>
    
    <Row className='py-5 '>
      <Col sm={6} lg={3}>  
    <h5 className='h5 text-center mt-2'>Get In Touch With Us</h5>
    <div  className="d-flex justify-content-center align-items-center">
    <Nav defaultActiveKey="/home"  className="flex-column"  >
    <Nav.Link href="/location" class="text-white">Find a Location Near You</Nav.Link>
    <Nav.Link href="/contact" class="text-white">Contact Us</Nav.Link>
    <Nav.Link href="/contact" class="text-white">Lost or Stolen Card</Nav.Link>
   


  </Nav>
  </div>
  </Col>
      <Col  sm={6} lg={3}>
      
    <h5 className='h5 text-center mt-2'>E-banking</h5>
    <div  className="d-flex justify-content-center align-items-center">
    <Nav defaultActiveKey="/home" className="flex-column ">
    <Nav.Link href="/MobileBanking" class="text-white">Mobile Banking</Nav.Link>
    <Nav.Link href="/OnlineBanking" class="text-white">Online Banking</Nav.Link>
    <Nav.Link href="/EBilling" class="text-white">E-Bill Pay</Nav.Link>
   
  </Nav>


  </div>
      </Col>
      <Col  sm={6} lg={3}> 
      <h5 className='h5 text-center mt-2'>Take the Next Step</h5>
    <div  className="d-flex justify-content-center align-items-center">
    <Nav className="flex-column ">
    <Nav.Link href="/loans" class="text-white">Buy a New Car</Nav.Link>
    <Nav.Link href="/loans" class="text-white">Buy a Home</Nav.Link>
    <Nav.Link href="/loans" class="text-white">Start Saving</Nav.Link>
    
  </Nav>
      </div>
      </Col>
      <Col  sm={6} lg={3}>

    
  



    <h5 className='h5 text-center mt-2'>Helpful Information</h5>
    <div  className="d-flex justify-content-center align-items-center">
    <Nav defaultActiveKey="/home" className="flex-column ">
    <Nav.Link href="/privacy" class="text-white">Privacy Policy</Nav.Link>
    <Nav.Link href="/fees-disclosure" class="text-white">Fee Disclosure</Nav.Link>
    <Nav.Link href="/fraud-protection" class="text-white">Fraud Protecion</Nav.Link>
    
  </Nav>






   
    </div>


      
      
      </Col>
    </Row>


    </div>
    </Container>
    <footer className='footer-container' fluid>        
        <Row>
        <Col sm={6} className='mt-3 mx-auto text-white'><p className="copyright text-white">&copy; {new Date().getFullYear()} GN Bank.  All Rights Reserved.</p></Col>
        <Col sm={6} className='mt-0 mx-auto py-1'><div id="logos-1" class="footer-logos">
					{/* <a href="speedbump18d0.html?link=https://www.hud.gov/" id="ehl" class="icon-ehl"><span class="visuallyhidden">Equal Housing Lender</span></a>
					<a href="speedbumpe586.html?link=https://www.fdic.gov/" id="fdic" class="icon-fdic"><span class="visuallyhidden">Federal Deposit Insurance Corporation</span></a> */}

          <img src={fdic} width="150px" height="45px"/>
                </div></Col>
      </Row>
        
        </footer>

        </>
  )
}

export default Footer