import React from 'react';
import { Table } from 'react-bootstrap';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import "./Cd.css"
const CDsTable = () => {
  const cdsData = [
    { term: '6 Months', rate: '0.55%', apy: '0.55%' },
    { term: '12 Months', rate: '0.75%', apy: '0.75%' },
    { term: '24 Months', rate: '1.00%', apy: '1.00%' },
    { term: '36 Months', rate: '1.25%', apy: '1.26%' },
    { term: '60 Months', rate: '1.50%', apy: '1.51%' },
  ];


  return (

    <div>
    {/* Hero Section */}
    <section className="hero-sections text-center text-white py-5">
      <Container>
        <p className='h1 mt-4 gn-title'>Certificates of Deposit</p>
        <p className="lead gn-secondary-title">
        CDs offer competitive rates that are guaranteed for the term of the deposit. Invest for as short as 30 days, or as long as two years for an even higher interest rate, then renew it as many times as you like.
        </p>
        <Button variant="light" size="lg" className='bg-button gn-button'>
          Compare All Cards
        </Button>
      </Container>
    </section>
    <div className="container my-5">
      <h2 className="text-center mb-4">Certificates of Deposit (CDs)</h2>
      <p className="text-center text-muted">
        Secure your savings with competitive rates and flexible terms.
      </p>
      <Table responsive="sm" bordered className="text-center">
        <thead>
          <tr>
            <th>Term</th>
            <th>Interest Rate</th>
            <th>APY (Annual Percentage Yield)</th>
          </tr>
        </thead>
        <tbody>
          {cdsData.map((cd, index) => (
            <tr key={index}>
              <td>{cd.term}</td>
              <td>{cd.rate}</td>
              <td>{cd.apy}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>

    </div>
  );
};

export default CDsTable;
