import React from 'react'
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'
import {  FloatingLabel, Form,  Button,ListGroup  } from 'react-bootstrap'
import myimg from '../../assets/images/gn_bank_cover.jpeg'
import './Location.css'
import about from '../../assets/images/ContentImageHandler4b97.png'
import HeroSection from '../../components/HeroSection/HeroSection'
import LocationHero from '../../components/LocationHero/LocationHero'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faCoffee} from '@fortawesome/free-solid-svg-icons/faCoffee'
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone'
import {faMailBulk} from '@fortawesome/free-solid-svg-icons/faMailBulk'
import {faLocation} from '@fortawesome/free-solid-svg-icons/faLocation'

import styled from 'styled-components'

const Location = () => {
  const BranchContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const Header = styled.h1`
  color: #0047AB;
  text-align: center;
`;

const InfoSection = styled.div`
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #0047AB;
  border-radius: 8px;
`;

const InfoItem = styled.p`
  margin: 5px 0;
`;

const MapContainer = styled.iframe`
  width: 100%;
  height: 300px;
  border: 0;
  border-radius: 8px;
  margin-top: 20px;
`;

  const branchData = {
    name: "GN Bank - 46th Street Branch",
    address: "4619 S King Drive IL 60653",
    phone: "(773) 624-2000",
    hours: [
        { day: "Monday - Friday", time: "8:30 AM - 4:30 PM" },
        { day: "Wednesday", time: "8:30 AM - 12:00 PM" },
        { day: "Saturday", time: "8:30 AM - 12:00 PM" },
        { day: "Sunday", time: "Closed" },
    ],
    atm: [
      { day: "Monday - Friday", time: "8:30 AM - 4:30 PM" },
      { day: "Wednesday", time: "8:30 AM - 12:00 PM" },
      { day: "Saturday", time: "8:30 AM - 12:00 PM" },
      { day: "Sunday", time: "Closed" },
  ],
};
  return (


    <>
    <LocationHero/>
      <Container className='py-2'></Container>












      <Container fluid={true}>
  
     
     


        <main className="">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-6 col-lg-6 col-xl-7 ">

                        <div className="my-5">
             


                        <>

                        {/* <div className='address'></div> */}
  
  <h1 className='address gn-title'>{branchData.name}</h1>

            <p className='gn-titles'><strong>Address:</strong> {branchData.address}</p>
            <p className='gn-titles'><strong>Phone:</strong> {branchData.phone}</p>
            <InfoSection>
                <h3>Directions:</h3>
                <MapContainer
                    src="https://www.google.com/maps/embed/v1/place?q=GN%20Bank%2C%20Chicago%2C%20IL&key=YOUR_GOOGLE_MAPS_API_KEY"
                    loading="lazy"
                ></MapContainer>
            </InfoSection>
  
  
  </>


                        </div>
                    </div>

                    <div className='col-md-6 col-lg-6 col-xl-5 mt-4 '>
                      <div className='py-5'></div>
                      <div className='py-3'></div>
                      <h2 className='text-center  h3 gn-title'>Lobby Hours</h2>
                      
                      {branchData.hours.map((hour, index) => (

                    <ListGroup as="ul" variant="flush">

                    

                    


                    
      <ListGroup.Item as="li">
        <h5 className='gn-titles '>{hour.day}: {hour.time}</h5>
      </ListGroup.Item>
      {/* <ListGroup.Item as="li"><h5 className='h5'><FontAwesomeIcon icon={faMailBulk}  className=' mr-5 ' /> E-mail: support@gnbank.net</h5></ListGroup.Item>
      <ListGroup.Item as="li">
      <p className='mr-4 pr-3 h6'><FontAwesomeIcon icon={faLocation}  className=' mr-5'/> 4619 S. King Drive , Chicago , IL 60653 </p>
      </ListGroup.Item> */}
      <ListGroup.Item as="li"></ListGroup.Item>

   
    </ListGroup>
  ))}













<h2 className='text-center mb-2 mt-3 h3 gn-title'>Drive Up Hours</h2>
                      
                      {branchData.atm.map((hour, index) => (

                    <ListGroup as="ul" variant="flush">

                    

                    


                    
      <ListGroup.Item as="li">
        <h5 className='gn-titles'>{hour.day}: {hour.time}</h5>
      </ListGroup.Item>
      {/* <ListGroup.Item as="li"><h5 className='h5'><FontAwesomeIcon icon={faMailBulk}  className=' mr-5 ' /> E-mail: support@gnbank.net</h5></ListGroup.Item>
      <ListGroup.Item as="li">
      <p className='mr-4 pr-3 h6'><FontAwesomeIcon icon={faLocation}  className=' mr-5'/> 4619 S. King Drive , Chicago , IL 60653 </p>
      </ListGroup.Item> */}
      <ListGroup.Item as="li"></ListGroup.Item>

   
    </ListGroup>
  ))}







                    </div>
                </div>
            </div>
        </main>
    </Container>










    
 

   </>
  )
}

export default Location

