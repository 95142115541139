import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import { MemoryRouter, Routes, Route, NavLink, Router, BrowserRouter } from 'react-router-dom';
import About from './screen/About/About';
import Home from './screen/Home/Home';

import Contact from './screen/Contact/Contact';

import Mynav from './components/Header/Header';
import Header from './components/Header/Header';
import { Container } from 'react-bootstrap';
import Footer from './components/Footer/Footer';

import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/400.css"; // Specific weight
import "@fontsource/inter/700.css"; // Optional for bold text
import "swiper/css";
import { Provider, useDispatch } from 'react-redux';
import store from './redux/store';
import { fetchShows } from './redux/actions/showsactions';




import Privacy from './screen/Privacy/Privacy';

import * as serviceWorker from "./serviceWorker"



import DownloadPage from './DownloadPage';
import Mobile from './components/Header/Mobiles';
import Mobiles from './components/Header/Mobiles';
import Checking from './screen/Checking';
import Savings from './screen/Savings';
import CreditCards from './screen/CreditCards/CreditCards';
import CDsTable from './screen/CD/Cd';
import Loan from './screen/loan/Loan';
import BusinessChecking from './screen/BusinessChecking';
import BusinessSavings from './screen/BusinessSavings';
import BusinessCreditCards from './screen/BusinessCreditCards/BusinessCreditCards';
import BusinessLoans from './screen/Businessloan/BusinessLoan';
import MobileBankingPage from './screen/MobilePayment/MobilePaymentPage';
import OnlineBankingPage from './screen/OnlineBanking/OnlineBankingPage';
import EBillingPage from './screen/OnlineBanking/OnlineBankingPage';
import EBilling from './screen/EBilling/EBillingPage';
import DirectDepositPage from './screen/DirectDeposit/DirectDepositPage';
import Fees from './screen/Fees/Fees';
import Location from './screen/Location/Location';

// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (e) => {
//   // Prevent the default behavior of the prompt
//   e.preventDefault();
//   // Stash the event so it can be triggered later
//   deferredPrompt = e;
// });


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  
  <React.StrictMode>
    {/* <Provider store={store}> */}

   
<BrowserRouter>

   {/* <Container className="main-Container mt-3"> */}
   <Header/>
 <Mobiles/>
<Routes>

<Route exact   path="/" Component={App}/>
<Route  exact  path="/about" Component={About}/>




<Route exact path="/contact" Component={Contact} />

<Route  exact path="/privacy" Component={Privacy}/>

<Route  exact path="/checking" Component={Checking}/>
<Route  exact path="/savings" Component={Savings}/>
<Route  exact path="/cd" Component={CDsTable}/>
<Route  exact path="/credit-card" Component={CreditCards}/>
<Route  exact path="/loans" Component={Loan}/>
<Route  exact path="/BusinessChecking" Component={BusinessChecking}/>
<Route  exact path="/Businesssavings" Component={BusinessSavings}/>
<Route  exact path="/cd" Component={CDsTable}/>
<Route  exact path="/BusinessCredit-Card" Component={BusinessCreditCards}/>
<Route  exact path="/BusinessLoans" Component={BusinessLoans}/>
<Route exact path="/download" Component={DownloadPage}/>
<Route exact path="/MobileBanking" Component={MobileBankingPage}/>
<Route exact path="/OnlineBanking" Component={OnlineBankingPage}/>
<Route exact path="/EBilling" Component={EBilling}/>
<Route exact path="/DirectDeposit" Component={DirectDepositPage}/>
<Route exact path="/Fees-Disclosure" Component={Fees}/>
<Route exact path="/location" Component={Location}/>
</Routes>


{/* </Container> */}
<Footer/>
</BrowserRouter>

{/* </Provider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();