import logo from './logo.svg';
import './App.css';
import mynav from './components/Header/Header';
import Mynav from './components/Header/Header';
// import { MemoryRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import "swiper/css";
import { Button, Row, Col, Card } from 'react-bootstrap';
import About from './screen/About/About';
import Home from './screen/Home/Home';

import Contact from './screen/Contact/Contact';

import Slider from './components/Slider/Slider';
import {Swiper, SwiperSlide} from 'swiper/react'

import moment from 'moment'

import 'swiper/css/pagination';
import 'swiper/css';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/effect-fade';
import axios from 'axios'
import { useEffect, useState,  } from 'react';
import { Thumbs } from 'swiper/modules';
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/400.css"; // Specific weight
import "@fontsource/inter/700.css"; // Optional for bold text


import 'swiper/css/navigation';
import SpinnerComponents from './components/Spinner/SpinnerComponents';

import Feature from './components/Feature/Feature';
import HeroSection from './components/HeroSection/HeroSection';
import BankServices from './components/BankServices/BankServices';
import CardGrid from './components/CardGrid'
import img from "../src/assets/images/ContentImageHandler855f.jpg"
import img2 from "../src/assets/images/ContentImageHandlercabe.jpg"
import img3 from "../src/assets//images/ContentImageHandler14be.jpg"
import GNBankCardGrid from './components/GNBankCardGrid';

import ChimeStyleSlider from './components/Slides/ChimeStyleSlider';
import ChimeSlide from './components/Slides/ChimeSlide';
import SideBySideSlide from './components/Slides/SideBySideSlide';


function App() {
  
      
// const navigate = useNavigate()

    
    const Router = () => {


     // navigate("/MobileBanking")


    }
     


  return (

<>
{/* <Slider /> */}



{/* <ChimeStyleSlider/> */}


{/* <ChimeSlide/> */}

<SideBySideSlide/>

{/* <HeroSection/> */}

    {/* <Container className="p-3"> */}
    <div className="App">

{/* <Container fluid={true}> */}
<BankServices/>
{/* </Container> */}

<div className='py-4'></div>

<div className='div-wrapper'>

    <Container className='py-3' >


    <Row className='mb-3 ' >




<Col md={6}>

<img src={img2} width="100%"/>

</Col>






<Col md={6} >
<div className=''>

<h2 className='gn-middle-text gn-title text-left h2 '>STAY CONNECTED, ENJOY PEACE OF MIND</h2>

<p className='card-text'>
Download the GN Bank app.
</p>

<div className='py-2'>
<Button className=' gn-button gn-middle-button btn' onClick={() => Router()}>Learn More</Button>
</div>
</div>

</Col>
</Row>


</Container>

</div>
<div className='py-3'></div>

<Container   >



<Row className='mb-3 '>







<Col md={6}>
<div className='bg-texts'>

<h2 className='gn-title gn-middle-text text-left h2'>ONLINE BANKING</h2>
<p className='card-text'>Making the internet a brand-new experience </p>
{/* <p className='text-dark'>Online banking can save you a lot of time and effort, you can undertake most transactions from the comforts of your home. However, 
it is crucial to use internet banking safely.</p> */}

</div>

<div className='py-2'>
<Button className='bg-button gn-button gn-middle-button btn' onClick={() => Router()}>Learn More</Button>

</div>

</Col>


<Col md={6}>

<img src={img3} width="100%" height="356px"/>

</Col>






</Row>





</Container>


    </div>

   

    {/* <div className='bg-darks'>
    <Row >
     

      

      <Col md={7} className='mt-2 ml-5 '>
      <div className='let-contact' >

      
     <h2 className='text-justify'>HOW CAN WE HELP YOU?</h2> 
<h2 className='text-justify'>CONTACT US TODAY</h2>
      </div>
      </Col>



      <Col md={5} className='text-justify'>

      <ul className=' square-list'>

        <li>
        Find a Location or ATM near you
        </li>

        <li>
        Call and speak with a Universal Banker
        </li>

        <li>
        Send us a question using email
        </li>
      </ul>
   
     
    
      </Col>

    
    </Row>


    </div> */}
    {/* </Container> */}


 

    </>
  );
}

export default App;
