import React,{useState} from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {LinkContainer} from 'react-router-bootstrap'
import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MemoryRouter, Routes, Route, NavLink } from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import About from '../../screen/About/About';
import Home from '../../screen/Home/Home';

 import channel from "../../assets/images/images.png"
import Contact from '../../screen/Contact/Contact';


import styled from 'styled-components';

import { useNavigate } from "react-router-dom";

import LoginModal from '../LoginModal/loginModal';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faSearch, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import './Header.css'
import SearchOverlay from '../SearchOverlay';




const StyledNavDropdown = styled(NavDropdown)`
    .dropdown-menu {
        display: none;
    }

    &:hover .dropdown-menu {
        display: block;
        margin-top: 0;
    }
`;
const Header = () => {



  const NavbarContainer = styled.nav`
  background-color: #0047AB;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const NavItem = styled.div`
  position: relative;
`;

const NavLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
      text-decoration: underline;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #0047AB;
  border-radius: 5px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  z-index: 100;

  a {
      display: block;
      padding: 10px 20px;
      color: #0047AB;
      text-decoration: none;

      &:hover {
          background-color: #0047AB;
          color: #ffffff;
      }
  }
`;





  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);



  const [showDropdown, setShowDropdown] = useState(false);

  const [showDropdown1, setShowDropdown1] = useState(false);


  const [showDropdown2, setShowDropdown2] = useState(false);


  const [showDropdown3, setShowDropdown3] = useState(false);

  const [showDropdown4, setShowDropdown4] = useState(false);

  const navigate = useNavigate();

  const handleIconClick = () => {
    navigate("/location"); // Replace "/target-page" with the desired route
  };

  const HomeClick = () => {
    navigate("/"); // Replace "/target-page" with the desired route
  };
  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const getTest = () => {

    window.open()
  }

  const handleMouseEnter1 = () => {
    setShowDropdown1(true);
  };

  const handleMouseLeave1 = () => {
    setShowDropdown1(false);
  };





  const handleMouseEnter2 = () => {
    setShowDropdown2(true);
  };

  const handleMouseLeave2 = () => {
    setShowDropdown2(false);
  };



  const handleMouseEnter3 = () => {
    setShowDropdown3(true);
  };

  const handleMouseLeave3 = () => {
    setShowDropdown3(false);
  };




  const handleMouseEnter4 = () => {
    setShowDropdown4(true);
  };

  const handleMouseLeave4 = () => {
    setShowDropdown4(false);
  };

const handleCustomerPortal = () => {


  window.open("https://portal.gnbank.net")
}


  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (

    <div className='desktop'>
    
   
    <Navbar expand="lg" className="bg-body-teritary ">
       
    <Container className=''>
      <Navbar.Brand to="/"><img src={channel}  style={{width: '50px', height: '50px' }} className="ml-5" onClick={HomeClick}/></Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '100px' }}
          navbarScroll
        >
          {/* <Nav.Link to="/">Home</Nav.Link> */}
          {/* <LinkContainer to="/">
          <Nav.Link>Home</Nav.Link>
          </LinkContainer> */}






{/* <StyledNavDropdown title="Services" id="services-dropdown">
                            <NavDropdown.Item href="/services/checking">Checking Accounts</NavDropdown.Item>
                            <NavDropdown.Item href="/services/savings">Savings Accounts</NavDropdown.Item>
                            <NavDropdown.Item href="/services/loans">Loan Services</NavDropdown.Item>
                        </StyledNavDropdown> */}


          



          <NavDropdown title="PERSONAL" id="navbarScrollingDropdown" 
          
          
       
          show={showDropdown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          
          
          
          >
          {/* <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer> */}

<LinkContainer to="/checking" className="dropdown-text">
            <NavDropdown.Item >
              Checking
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/savings" className="dropdown-text">
            <NavDropdown.Item >
              Savings
            </NavDropdown.Item>
            </LinkContainer>

            {/* <LinkContainer to="/credit-card" className="dropdown-text">
            <NavDropdown.Item>
              Credit Cards
            </NavDropdown.Item>
            </LinkContainer> */}

            <LinkContainer to="/cd" className="dropdown-text">
            <NavDropdown.Item>
              CD
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/loans" className="dropdown-text">
            <NavDropdown.Item>
             Personal Loans
            </NavDropdown.Item>
            </LinkContainer>



{/* <LinkContainer to="/terms">
            <NavDropdown.Item>
              TERMS
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/privacy">
            <NavDropdown.Item >
              PRIVACY
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/download">
            <NavDropdown.Item >
              Download
            </NavDropdown.Item>
            </LinkContainer> */}
           
          </NavDropdown>














          <NavDropdown title="BUSINESS" id="navbarScrollingDropdown" 
          
          
          show={showDropdown2}
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
          
          
          >
          {/* <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer> */}

<LinkContainer to="/BusinessChecking" className="dropdown-text">
            <NavDropdown.Item >
              Business Checking
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/BusinessSavings" className="dropdown-text">
            <NavDropdown.Item >
              Business Savings
            </NavDropdown.Item>
            </LinkContainer>

            {/* <LinkContainer to="/BusinessCredit-Card" className="dropdown-text">
            <NavDropdown.Item>
              Business Credit Cards
            </NavDropdown.Item>
            </LinkContainer> */}

            <LinkContainer to="/cd" className="dropdown-text">
            <NavDropdown.Item>
              CD
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/BusinessLoans" className="dropdown-text">
            <NavDropdown.Item>
             Business Loans
            </NavDropdown.Item>
            </LinkContainer>



{/* <LinkContainer to="/terms">
            <NavDropdown.Item>
              TERMS
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/privacy">
            <NavDropdown.Item >
              PRIVACY
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/download">
            <NavDropdown.Item >
              Download
            </NavDropdown.Item>
            </LinkContainer> */}
           
          </NavDropdown>






          <NavDropdown title="E-BANKING" id="navbarScrollingDropdown2" 
          
          
          
          show={showDropdown3}
          onMouseEnter={handleMouseEnter3}
          onMouseLeave={handleMouseLeave3}
          
          
          >
          {/* <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer> */}

<LinkContainer to="/MobileBanking" className="dropdown-text">
            <NavDropdown.Item >
              Mobile Banking
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/OnlineBanking" className="dropdown-text">
            <NavDropdown.Item >
               Online Banking
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/EBilling" className="dropdown-text">
            <NavDropdown.Item>
              E-Billing
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/DirectDeposit" className="dropdown-text">
            <NavDropdown.Item>
              Direct Deposit
            </NavDropdown.Item>
            </LinkContainer>

         
           
          </NavDropdown>









         
          
          <NavDropdown title="About" id="navbarScrollingDropdown5" 
          
          
          show={showDropdown4}
          onMouseEnter={handleMouseEnter4}
          onMouseLeave={handleMouseLeave4}
          
          
          >
          <LinkContainer to="/about">
            <NavDropdown.Item>About US</NavDropdown.Item>
            </LinkContainer>

<LinkContainer to="/contact" className="dropdown-text">
            <NavDropdown.Item >
              Contact US
            </NavDropdown.Item>
            </LinkContainer>


<LinkContainer to="/location">
            <NavDropdown.Item>
             Location
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/privacy">
            <NavDropdown.Item >
              Privacy
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/Fees-Disclosure">
            <NavDropdown.Item >
              Fees Disclosure
            </NavDropdown.Item>
            </LinkContainer>
           
          </NavDropdown>

          {/* <LinkContainer to="/news">
          <Nav.Link  className="text-primary">
            News
          </Nav.Link>
          </LinkContainer> */}

          

          {/* <LinkContainer to="/live">
          <Nav.Link  className="text-primary">
            live TV
          </Nav.Link>
          </LinkContainer> */}

         
        
         

          {/* <Link to="/live">Live</Link> */}
          {/* <Button variant="outline-primary"><Link to="/live">Live</Link></Button> */}
       
          {/* <h4 className='mt-2 text-white '><Link to="/live">WATCH</Link></h4> */}

         
         <div onClick={() => {handleCustomerPortal()}} className='py-2 gn-title-customer'>

         CUSTOMER PORTAL

          </div>
         
         
       
        </Nav>
       

      </Navbar.Collapse>




      <nav className="nav-icons  ml-2 mr-2">
        {/* Location Icon */}
        <FontAwesomeIcon icon={faLocationDot} className="icon" onClick={handleIconClick} />

        <SearchOverlay/>

        {/* Search Icon */}
        {/* <FontAwesomeIcon icon={faSearch} className="icon" /> */}

        {/* Question Mark Icon */}
        <FontAwesomeIcon icon={faQuestionCircle} className="icon" />

<div className='dt-login'>

      
         <div className='dt-logins bg-buttons' onClick={handleShowModal}>LOGIN</div>
        
         {/* <button className="btn bg-buttons " onClick={handleShowModal}>
          Login
        </button> */}
           </div> 
         
       


          


      </nav>

    
         
    </Container>
   

  </Navbar>

  <LoginModal show={showModal} handleClose={handleCloseModal} />

  {/* <div className='border-bottom'></div> */}


  
  </div>
 
  )
}

export default Header