import React from 'react';
import { Table } from 'react-bootstrap';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import "./Fees.css"
const Fees = () => {
  const Checking = [
    { AccountType: 'Absolute Free Checking', Minimum : '$100', Fee: '$0' },
  
  ];


  const Business = [
    { AccountType: 'Small Business Checking', Minimum : '$300', Fee: '$25' },
  
  ];


  const Savings = [
    { AccountType: 'Statement Savings ', Minimum:'$999.99', Fee: '$15.00**' },
    { AccountType: 'MMDA', Minimum: '$2,500.00', Fee: '$15.00**' },
  
  ];


  const Services = [
    { FeesType: 'ATM Cash Withdrawal Fees ', Desc:'$3.50 per each transactions for non GN Bank customers.' },
    { FeesType: 'Change your ATM Card Personal Identification Number (PIN) ', Desc:'$5.00 per change request/ Free at GN Bank ATM' },
    { FeesType: 'Replace a lost ATM / Debit Card', Desc:'$15.00 per card' },
    { FeesType: 'Foreign ATM ', Desc:'$15.00 per card' }

  ];




  const Overdraft = [
    { FeesType: 'Occurs when GN Bank pays for a transaction (including ACH transactions) when your account does not have enough funds.', title:"Non-Sufficient Funds Fee" , Desc:"$35.00 per transaction(current fee) "},
    { FeesType: 'Occurs for any check or ACH transaction that GN Bank returns unpaid due to insufficient funds in an account', title:"Returned Item Fee" , Desc:"$35.00 per transaction(current fee) "},
    { FeesType: '*The Extended overdrawn Account Fee is charged daily for each day the account remains overdrawn. Please enroll in online banking and monitor your account balance closely*', title:"Extended Overdrawn Account Fee" , Desc:"$35.00 per transfer(Effective September 16, 019)"},
    { FeesType: 'Occurs when there are excessive transactions of the six transfer/withdrawal limitations (Excessive transactions are transfers and/or withdrawals)', title:"Savings Account Fee" , Desc:"$3.00 per transfer transaction(Effective September 16, 2019) "}

  ];



  return (

    <div>
    {/* Hero Section */}
    <section className="hero-sections-fees text-center text-white py-5">
      <Container>
        <p className='h1 mt-4 gn-title'>Fees</p>
        <p className="lead gn-secondary-title">
Below are our rates and Charges.
        </p>
        {/* <Button variant="light" size="lg" className='bg-button gn-button'>
          Compare All Cards
        </Button> */}
      </Container>
    </section>
    <div className="container my-5">
      <h2 className="text-center mb-4">Personal Checking</h2>
      <p className="text-center text-muted">
        Secure your savings with competitive rates and flexible terms.
      </p>
      <Table responsive="sm" bordered className="text-center">
        <thead>
          <tr>
          <th>Account Type</th>
            <th>Minimum Balance to Open </th>
            <th>Monthly Maintenance Fee</th>
          </tr>
        </thead>
        <tbody>
          {Checking.map((cd, index) => (
            <tr key={index}>
              <td>{cd.AccountType}</td>
              <td>{cd.Minimum}</td>
              <td>{cd.Fee}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>




    <div className="container my-5">
      <h2 className="text-center mb-4">Business Checking</h2>
      <p className="text-center text-muted">
        Secure your savings with competitive rates and flexible terms.
      </p>
      <Table responsive="sm"  className="text-center"  bordered striped>
        <thead>
          <tr>
            <th>Account Type</th>
            <th>Minimum Balance to Open </th>
            <th>Monthly Maintenance Fee</th>
          </tr>
        </thead>
        <tbody>
          {Business.map((cd, index) => (
            <tr key={index}>
              <td>{cd.AccountType}</td>
              <td>{cd.Minimum}</td>
              <td>{cd.Fee}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>












    <div className="container my-5">
      <h2 className="text-center mb-4">Personal Savings</h2>
      <p className="text-center text-muted">
        Secure your savings with competitive rates and flexible terms.
      </p>
      <Table responsive="sm"  className="text-center"  bordered striped>
        <thead>
          <tr>
            <th>Account Type</th>
            <th>Minimum Balance to Open </th>
            <th>Monthly Maintenance Fee</th>
          </tr>
        </thead>
        <tbody>
          {Savings.map((cd, index) => (
            <tr key={index}>
              <td>{cd.AccountType}</td>
              <td className='mx-auto'><section className='text-justify'> {cd.Minimum}</section></td>
              <td>{cd.Fee}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>







    <div className="container my-5">
      <h2 className="text-center mb-4">Service Charges</h2>
      <p className="text-center text-muted">
        Service Charges
      </p>
      <Table responsive="sm"  className="text-center"  bordered striped>
        <thead>
          <tr>
            <th>ATM/Debit Card Fees</th>
            <th> </th>
            
          </tr>
        </thead>
        <tbody>
          {Services.map((cd, index) => (
            <tr key={index}>
              <td><p>{cd.FeesType}</p></td>
              <td className=''><p>{cd.Desc}</p></td>
              {/* <td>{cd.Fee}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>








    <div className="container my-5">
      <h2 className="text-center mb-4">OverDraft Charges </h2>
      <p className="text-center text-muted">
        {/* Service Charges */}
      </p>
      <Table responsive="sm"  className="text-center"  bordered striped>
        <thead>
          <tr>
            <th>OverDraft Charges</th>
            <th> </th>
            
          </tr>
        </thead>
        <tbody>
          {Overdraft.map((cd, index) => (
            <tr key={index}>
              <td> <p>{cd.title}</p><p>{cd.FeesType}</p></td>
              <td className=''><p>{cd.Desc}</p></td>
              {/* <td>{cd.Fee}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>




    </div>
  );
};

export default Fees;
