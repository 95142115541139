import React from "react";
import { motion } from "framer-motion";
import "./HeroSection-about.css";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
const HeroSection = () => {
  return (
    <div>
    {/* Hero Section */}
    <section className="hero-sections-about text-center text-white py-5">
      <Container fluid>
        <div className='bgs2'>
        <p className='h1 mt-4 gn-title text-center'>Our Story</p>
        <p className="lead gn-secondary-title text-center h1">
         About GN Bank
        </p>
        {/* <Button variant="light" size="lg" className='bg-buttons'>
          Compare All Cards
        </Button> */}

        </div>
      </Container>
    </section>
    </div>
  );
};

export default HeroSection;