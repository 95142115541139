import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const SearchContainer = styled.div`
    background: #fff;
    padding: 20px;
    width: 400px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 10px;
    border: 2px solid rgb(122, 6, 29);
    border-radius: 6px;
    margin-bottom: 15px;
`;

const SearchResult = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #e0e0e0;
    }
`;

const SearchIcon = styled(FaSearch)`
    cursor: pointer;
    color:rgb(109, 4, 39);
    font-size: 17px;

    &:hover {
        color:rgb(111, 5, 37);
    }
`;

const SearchOverlay = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const searchData = [
        { name: 'Home', path: '/' },
        { name: 'About Us', path: '/about' },
        { name: 'Checking Accounts', path: '/checking' },
        { name: 'Savings Accounts', path: '/savings' },
        { name: 'Loan Services', path: '/loans' },
        { name: 'Contact', path: '/contact' },
        {name: 'privacy', path: '/privacy'}
    ];

    const filteredResults = searchData.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleResultClick = (path) => {
        navigate(path);
        setIsOpen(false);
    };

    return (
        <>
            <SearchIcon onClick={() => setIsOpen(true)} />

            <Overlay isOpen={isOpen}>
                <SearchContainer>
                    <h3>Search Pages</h3>
                    <SearchInput
                        type="text"
                        placeholder="Search for pages..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    {filteredResults.map((result) => (
                        <SearchResult
                            key={result.path}
                            onClick={() => handleResultClick(result.path)}
                        >
                            {result.name}
                            <FaSearch />
                        </SearchResult>
                    ))}

                    <button
                        className="btn btn-secondary mt-3"
                        onClick={() => setIsOpen(false)}
                    >
                        Close
                    </button>
                </SearchContainer>
            </Overlay>
        </>
    );
};

export default SearchOverlay;
