import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import img from "../../assets/images/saving.png"
import invest from "../../assets/images/investment.png"
import business from "../../assets/images/cooperation.png"

const BankServices = () => {
  const services = [
    {
      image: `${img}`,
      title: 'Personal Banking',
      description: 'Access your funds, transfer money, and manage accounts with ease.',
    },
    {
      image: `${business}`,
      title: 'Business Banking',
      description: 'Tailored solutions to help your business grow and succeed.',
    },
    {
      image: `${invest}`,
      title: 'CD',
      description: 'We have a wide variety of CD accounts , at better rate. ',
    },
  ];

  return (
    <Container  className="">
    
      <Row>

        <h5 className='gn-title h5 py-4'> How May We Help You ?</h5>
        {services.map((service, index) => (
          <Col md={4} className="mb-1" key={index}>
            <Card>
              <Card.Img variant="top" src={service.image} />
              <Card.Body>
                <Card.Title ><h5 className='h5'>{service.title}</h5></Card.Title>
                {/* <Card.Text>{service.description}</Card.Text> */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BankServices;
