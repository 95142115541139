import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import "./OnlineBanking.css"
import moneytransfer from "../../assets/images/transference.png"
import deposit from "../../assets/images/deposit.png"
import bank from "../../assets/images/secure-image.jpg"
const EBillingPage = () => {
  const features = [
    {
      title: '24/7 Account Access',
      description:
        'Check your balances, transfer funds, and view transactions anytime, anywhere.',
      image: `${bank}`, // Replace with actual image URL
    },
    {
      title: 'Bill Pay',
      description:
        'Easily pay bills online and set up automatic payments to save time.',
      image: `${moneytransfer}`, // Replace with actual image URL
    },
    {
      title: 'Mobile Banking',
      description:
        'Manage your account on the go with our secure and user-friendly mobile app.',
      image: `${deposit}`, // Replace with actual image URL
    },
    {
      title: 'E-Statements',
      description:
        'Go green with electronic statements that are easy to access and store.',
      image: `${bank}`, // Replace with actual image URL
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="hero-sections-online text-center text-white py-5">
        <Container>
          <h1>GN Bank Online Banking</h1>
          <p className="lead text-dark text-center">
            Manage your finances with ease and security—anytime, anywhere.
          </p>
          <Button variant="light" size="lg" className='gn-buttons bg-buttons'>
            Get Started
          </Button>
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 gn-secondary-title">Why Choose Online Banking?</h2>
          <Row>
            {features.map((feature, index) => (
              <Col md={6} lg={4} className="mb-4" key={index}>
                <Card className="h-100 shadow-sm">
                  <Card.Img variant="top" src={feature.image} alt={feature.title} />
                  <Card.Body>
                    <Card.Title>{feature.title}</Card.Title>
                    <Card.Text>{feature.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Security Section */}
      <section className="security-section">
        <Container className='py-5 '>
          <h2 className="text-center mb-4">Your Security, Our Priority</h2>

          <div className='secure-body'>
          <Row className=''>
            <Col md={6}>
              <p className='py-5'>
                We use the latest encryption and authentication technologies to
                protect your information.
              </p>
              <ul className=''>
                <li>Two-factor authentication for added security.</li>
                <li>Real-time alerts for suspicious activity.</li>
                <li>SSL encryption for all online transactions.</li>
              </ul>
            </Col>
            <Col md={6}>
              <img
                src={bank} 
              
                alt="Security"
                className="img-fluid"
              />
            </Col>
          </Row>
          </div>
        </Container>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta-section text-center text-white py-5">
        <Container>
          <h2>Ready to Bank Online?</h2>
          <p className="lead text-center">
            Join thousands of satisfied customers and take control of your finances today.
          </p>
          {/* <Button variant="light" size="lg">
            Enroll
          </Button> */}



 <div className='gn-button-div'>
          <Button  size="lg" className='gn-button'>
            Enroll
          </Button>
          </div>


        </Container>
      </section>
    </div>
  );
};

export default EBillingPage;
