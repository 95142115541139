import React from 'react'
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'

import myimg from '../../assets/images/gn_bank_cover.jpeg'
import './about.css'
import about from '../../assets/images/ContentImageHandler4b97.png'
import HeroSection from '../../components/HeroSection/HeroSection'
const About = () => {
  return (
    <>
     <HeroSection/>
      <Container className='py-2'></Container>
    <Container className='mt-5'>


  
      
        

       

<Row>
        <Col sm={5} className='bgs-image'>
          
          <img src={myimg} className='about-img mt-4' />
        
        
        
        </Col>
        <Col sm={7}>


<div className="p text-justify py-5 mt-5">

  <h2 className='h3 text-center gn-title'>About GN Bank </h2>

<p className='py-1  mt-5 text-dark'>At its inception in 1934, the Bank primarily served Chicago’s South Side community that had been underserved by other financial institutions. The Bank will continue to serve the South Side community as we provide services to the elderly and the poor that other banks do not offer. To achieve a stronger financial foundation and ensure its continued viability, GN Bank will diversify its customer base to other communities in Illinois and nationwide. The diversification, when achieved, will make the Bank better able to serve its customers.The Bank bears the Community Development Financial Institution (CDFI) trademark issued by the U S Department of Treasury. The Bank has been approved as a participant in the Minority Bank Deposit Program (MBDP), managed by the Department of Treasury’s Bureau of the Fiscal Service (Fiscal Service).</p>

</div>


        </Col>
      </Row>


    </Container>
 

   </>
  )
}

export default About
