import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

import bg from "../assets/images/ContentImageHandlerdcaa.jpg"
const Savings = () => {
  return (
    <div>
      {/* Hero Section */}
     

      
      <section className="hero-sections py-5 text-center bg-primary text-white">
      
        <Container className='mt-4'>
        <div className='bgs'>
          <p className='h1  gn-title '>Personal Savings Account</p>
          <p className="lead text-dark">
          We have several different personal savings options designed to meet your daily banking needs.

In addition, we offer electronic bill payment in all of our personal accounts as well as convenient 24-hour account access through our secure online, mobile, and telephone banking systems.

GN Bank is FDIC insured for up to $250,000.
          </p>
          <Button variant="light" size="lg" className='bg-buttons'>
            Open an Account
          </Button>

          </div>
        </Container>
       
      </section>

      {/* Benefits Section */}
      <section className="benefits-section py-5">
        <Container>
          <h2 className="text-center mb-4 gn-title">Key Benefits?</h2>
          <Row>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>No Monthly Fees</Card.Title>
                  <Card.Text>
                    Enjoy a hassle-free banking experience with no monthly maintenance fees.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Overdraft Protection</Card.Title>
                  <Card.Text>
                    Stay protected with options to cover overdrafts on your account.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Mobile Banking</Card.Title>
                  <Card.Text>
                    Manage your account anytime, anywhere with our mobile app.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section  py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 gn-title">Key Features</h2>
          <Row className='text-justify gn-row'>
            <Col md={4}>
            <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Free Online Bill Pay</Card.Title>
                  <Card.Text>
                    Pay Bill Directly using our website or mobile app
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>
            <Col md={4}>

            <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Secure Online Access</Card.Title>
                  <Card.Text>
                    Pay Bill Directly using our website or mobile app
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>

            <Col md={4}>

            <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>FDIC Insured</Card.Title>
                  <Card.Text>
                    Up to 250,000
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>
          </Row>





      



        </Container>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section py-5 text-center bg-darks text-white">
        <Container>
          <h2>Ready to Open Your Account?</h2>
          <p className="lead text-center">
            Start your journey to better banking today.
          </p>
          <div className='gn-button-div'>
          <Button  size="lg" onClick={() => {}} className='gn-button'>
            Open an Account
          </Button>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Savings;
