import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import app from "../../assets/images/apple.png"
import google from "../../assets/images/google.png"
import moneytransfer from "../../assets/images/transference.png"
import deposit from "../../assets/images/deposit.png"
import bank from "../../assets/images/bank.png"
import "./MobilePayment.css"
const MobileBankingPage = () => {
  const features = [
    {
      title: 'Account Management',
      description: 'View your account balance and transaction history anytime, anywhere.',
      icon: `${bank}`, // Replace with real icon/image
    },
    {
      title: 'Bill Payments',
      description: 'Pay your bills on time with our easy-to-use mobile banking app.',
      icon: `${moneytransfer}`, // Replace with real icon/image
    },
    {
      title: 'Money Transfers',
      description: 'Send and receive money securely with just a few taps.',
      icon: `${moneytransfer}`, // Replace with real icon/image
    },
    {
      title: 'Mobile Deposits',
      description: 'Deposit checks anytime by snapping a photo with your phone.',
      icon: `${deposit}`, // Replace with real icon/image
    },
  ];

  const steps = [
    'Download the GN Bank Mobile App from the App Store or Google Play.',
    'Log in with your existing GN Bank online banking credentials.',
    'Start managing your accounts and accessing features on the go!',
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="hero-sections-mobile text-center text-white py-5">
        <Container fluid={true} className='mt-5 text-dark'>
          <h1 className='text-white'>Mobile Banking Made Easy</h1>
          <p className="lead text-center text-white">
            Manage your finances on the go with GN Bank's secure and convenient mobile banking app.
          </p>
          {/* <Button variant="light" size="lg">

            Download the Apps
          </Button> */}
          <img src={app} />
          <img src={google} />
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 gn-title">Features of GN Bank Mobile Banking</h2>
          <Row>
            {features.map((feature, index) => (
              <Col md={6} lg={4} className="mb-4" key={index}>
                <Card className="h-100 text-center shadow-sm">
                  <Card.Img
                    variant="top"
                    src={feature.icon}
                    alt={feature.title}
                    className="p-3"
                  />
                  <Card.Body>
                    <Card.Title>{feature.title}</Card.Title>
                    <Card.Text>{feature.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Steps to Get Started Section */}
      <section className="steps-section py-5">
        <Container>
          <h2 className="text-center mb-4">How to Get Started</h2>
          <Row>
            {steps.map((step, index) => (
              <Col md={4} className="text-center mb-4" key={index}>
                <Card className="h-100 shadow-sm">
                  <Card.Body>
                    <h3>Step {index + 1}</h3>
                    <p>{step}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta-section text-center text-white py-5 gn-title">
        <Container>
          <h2>Take Your Bank Anywhere</h2>
          <p className="lead text-center ">
            Download the GN Bank Mobile App today and experience banking on the go.
           

       </p>
         <div className='gn-button-div text-center'>
          <Button  size="lg" className="mx-2  gn-title  gn-button ">
        
          <a href="https://apps.apple.com/us/app/gn-bank/id1593719853?itsct=apps_box_link&amp;itscg=30200"> Download on App Store</a>
          </Button>
          <Button  size="lg" className="mx-2  gn-title  gn-button">
            Get it on Google Play
          </Button>

          </div>
        </Container> 
      </section>
    </div>
  );
};

export default MobileBankingPage;
