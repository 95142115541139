import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import "./EBilling.css"
const EBilling = () => {

  const gnbuttonclick = () =>{

    window.open("https://web10.secureinternetbank.com/PBI_PBI1151/Login/271070924")
  }
  return (
    <div>
      {/* Hero Section */}
      <section className="hero-sections-ebilling text-center text-white py-5">
        <Container className='py-5'>
          <h1 className='gn-title'>GN Bank E-Billing</h1>
          <p className="lead  text-center gn-title">
            Simplify your life with secure, convenient electronic billing and payments.
          </p>
          <Button variant="light" size="lg" className='bg-buttons' onClick={() => {gnbuttonclick()}}>
            Enroll Now
          </Button>
        </Container>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 gn-title">Why Choose E-Billing?</h2>
          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Convenient</Card.Title>
                  <Card.Text>
                    Access your statements anytime, anywhere, from any device.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Secure</Card.Title>
                  <Card.Text>
                    Keep your personal information safe with encrypted delivery.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Eco-Friendly</Card.Title>
                  <Card.Text>
                    Reduce paper waste and help protect the environment by going green.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works-section py-5">
        <Container>
          <h2 className="text-center mb-4">How It Works</h2>
          <Row>
            <Col md={6}>
              <p>✔ Enroll online in minutes.</p>
              <p>✔ Receive your statements electronically instead of by mail.</p>
              <p>✔ View and pay your bills securely through our online banking portal.</p>
            </Col>
            <Col md={6}>
              <p>✔ Access historical statements anytime.</p>
              <p>✔ Set up email or text alerts for payment reminders.</p>
              <p>✔ Manage your billing preferences easily from your account.</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta-section text-center text-white py-5">
        <Container>
          <h2>Ready to Make the Switch?</h2>
          <p className="lead text-center">
            Enroll in E-Billing today and enjoy the benefits of secure, paperless billing.
          </p>
          <div className='gn-button-div'>
          <Button  size="lg" className='gn-button' onClick={() => {gnbuttonclick()}}>
            Get Started
          </Button>
          </div>
        </Container>
      </section>
    </div>
  );
};



export default EBilling;
