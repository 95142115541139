import React from 'react';
import { Table } from 'react-bootstrap';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import "./Privacy.css"
const Privacy = () => {
  const Facts = [
    
    { facts: 'Why?', Desc : 'Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.',More:[] },
    { facts: 'What?', Desc : 'The types of personal information we collect and share depend on the product or service you have with us. This information can include:',More:["Social Security number",
"Account balances",
"Payment history",
"Transaction history",
"Credit history",
"Wire transfer instructions"],
snippet: "When you are no longer our customer, we continue to share your information as described in this notice" },
    { facts: 'How?', Desc : 'All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reasons GN Bank chooses to share; and whether you can limit this sharing.', More:[] },
  
  ];


  const Business = [
    { AccountType: 'Small Business Checking', Minimum : '$300', Fee: '$25' },
  
  ];


  const Savings = [
    { AccountType: 'Statement Savings ', Minimum:'$999.99', Fee: '$15.00**' },
    { AccountType: 'MMDA', Minimum: '$2,500.00', Fee: '$15.00**' },
  
  ];


  const Services = [
    { FeesType: 'ATM Cash Withdrawal Fees ', Desc:'$3.50 per each transactions for non GN Bank customers.' },
    { FeesType: 'Change your ATM Card Personal Identification Number (PIN) ', Desc:'$5.00 per change request/ Free at GN Bank ATM' },
    { FeesType: 'Replace a lost ATM / Debit Card', Desc:'$15.00 per card' },
    { FeesType: 'Foreign ATM ', Desc:'$15.00 per card' }

  ];




  const Overdraft = [
    { FeesType: 'Occurs when GN Bank pays for a transaction (including ACH transactions) when your account does not have enough funds.', title:"Non-Sufficient Funds Fee" , Desc:"$35.00 per transaction(current fee) "},
    { FeesType: 'Occurs for any check or ACH transaction that GN Bank returns unpaid due to insufficient funds in an account', title:"Returned Item Fee" , Desc:"$35.00 per transaction(current fee) "},
    { FeesType: '*The Extended overdrawn Account Fee is charged daily for each day the account remains overdrawn. Please enroll in online banking and monitor your account balance closely*', title:"Extended Overdrawn Account Fee" , Desc:"$35.00 per transfer(Effective September 16, 019)"},
    { FeesType: 'Occurs when there are excessive transactions of the six transfer/withdrawal limitations (Excessive transactions are transfers and/or withdrawals)', title:"Savings Account Fee" , Desc:"$3.00 per transfer transaction(Effective September 16, 2019) "}

  ];



  return (

    <div>
    {/* Hero Section */}
    <section className="hero-sections-fees text-center text-white py-5">
      <Container>
        <p className='h1 mt-4 gn-title'>Privacy Policy</p>
        <p className="lead gn-secondary-title">
        </p>
        {/* <Button variant="light" size="lg" className='bg-button gn-button'>
          Compare All Cards
        </Button> */}
      </Container>
    </section>
    <div className="container my-5">
    
      <Table responsive="sm" bordered className="text-center">
        <thead>
          <tr>
          <th>Facts</th>
            <th>What does GN Bank do with your personal information? </th>
            
          </tr>
        </thead>
        <tbody>
          {Facts.map((cd, index) => (
            <tr key={index}>
              <td className='boot'>{cd.facts}</td>
              <td><p>{cd.Desc}</p>
                
                <ul className=" square-bullet">{cd.More.map(d => (
                  <li className='list-group-item'><p>{d}</p></li>
                 
                ))}
                </ul>
                </td>
             
            </tr>
          ))}
        </tbody>
      </Table>
    </div>




    














    </div>
  );
};

export default Privacy;
