import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

import bg from "../assets/images/ContentImageHandlerdcaa.jpg"
const BusinessSavings = () => {
  return (
    <div>
      {/* Hero Section */}
     

      
      <section className="hero-sections py-5 text-center bg-primary text-white">
    
        <Container className='mt-4'>
        <div className='bgs'>
          <p className='h1  gn-title '>Business Savings Account</p>
          <p className="lead text-dark">
          We have several business savings account products designed to provide you with a safe and secure way to save money at a competitive rate of interest. From Basic Savings to Money Market accounts to Jumbo CDs, our friendly personal banking staff can assist you in selecting the best option for you and your business.
          </p>
          <div className='gn-button-div'>
          <Button  size="lg" className='gn-button'>
            View Our Rates
          </Button>
          </div>
          </div>
        </Container>
       
      </section>

      {/* Benefits Section */}
      <section className="benefits-section py-5">
        <Container>
          <h2 className="text-center mb-4 gn-title">Key Benefits?</h2>
          <Row>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>No Monthly Fees</Card.Title>
                  <Card.Text>
                    Enjoy a hassle-free banking experience with no monthly maintenance fees.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Overdraft Protection</Card.Title>
                  <Card.Text>
                    Stay protected with options to cover overdrafts on your account.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Mobile Banking</Card.Title>
                  <Card.Text>
                    Manage your account anytime, anywhere with our mobile app.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section  py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 gn-title">Key Features</h2>
          <Row className='text-justify gn-row'>
            <Col md={4}>
            <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Free Online Bill Pay</Card.Title>
                  <Card.Text>
                    Pay Bill Directly using our website or mobile app
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>
            <Col md={4}>

            <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Secure Online Access</Card.Title>
                  <Card.Text>
                    Pay Bill Directly using our website or mobile app
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>

            <Col md={4}>

            <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>FDIC Insured</Card.Title>
                  <Card.Text>
                    Up to 250,000
                  </Card.Text>
                </Card.Body>
              </Card>
           
            </Col>
          </Row>





      



        </Container>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section py-5 text-center bg-darks text-white">
        <Container>
          <h2>Ready to Open Your Account?</h2>
          <p className="lead text-center">
            Start your journey to better banking today.
          </p>
          {/* <Button variant="light" size="lg">
            Open an Account
          </Button> */}


          <div className='gn-button-div'>
          <Button  size="lg" className='gn-button'>
            Open An Account
          </Button>
          </div>



        </Container>
      </section>
    </div>
  );
};

export default BusinessSavings;
