import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';


import trusted from "../assets/images/ContentImageHandlerce72.jpg"
import community from "../assets/images/ContentImageHandler4b97.png"

import black from "../assets/images/gn_bank_cover.jpeg"
const GNBankCardGrid = () => {
  const cardData = [
    {
      title: 'A Community Bank',
      image: `${community}`, // Replace with your image URL
      description:
        'GN Bank is dedicated to uplifting local communities by offering financial solutions tailored to your needs.',
    },
    {
      title: 'Black-Owned Legacy',
      image: `${black}`, // Replace with your image URL
      description:
        'Proud to be one of the few Black-owned banks in the nation, GN Bank empowers economic growth and equity.',
    },
    {
      title: 'Your Trusted Partner',
      image: `${trusted}`, // Replace with your image URL
      description:
        'With over 50 years of service, GN Bank provides reliable and innovative banking solutions.',
    },
  ];

  return (
    <Container className="py-5">
      <h2 className="text-center mb-4 gn-title h2">Why Choose GN Bank?</h2>
      <p className="text-center text-muted mb-4">
        GN Bank is more than a bank—we're your partner in achieving financial success while supporting our community.
      </p>
      <Row>
        {cardData.map((card, index) => (
          <Col md={4} className="mb-4" key={index}>
            <Card className="h-100 shadow-sm">
              <Card.Img variant="top" src={card.image} alt={card.title} className='footer-card-top' />
              <Card.Body>
                <Card.Title className='h5'>{card.title}</Card.Title>
                <Card.Text>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default GNBankCardGrid;
