import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import  "./CreditCards.css";

import bg from "../../assets/images/visa1.jpg"
import visa2 from "../../assets/images/visa2.jpg"
import visa3 from "../../assets/images/visa3.jpg"
const CreditCards = () => {
  const cards = [
    {
      title: 'Visa Platinum Cash Rewards Credit Card',
      description:
        'Let all those purchases work for you with a cash back rewards card Earn up to 3% cashback on every purchase, every day when you shop at select community-based merchants..',
      features:[  "Earn Unlimited 1% cash back on every purchase, every day.", "$0 Annual Fee.", "Cash Advance Fee","Foreign Transaction Fee. Up to 1%.", "Peace of mind Fraud Protection"
      ],
      image: `${bg}`, // Replace with actual image
      link: 'https://gnbank.yourcommunitycard.com/personal',
    },
    {
      title: 'Visa Platinum Rewards Credit Card',
      description:
        'Enjoy unlimited 3x pointt cash back on every purchase with no blackout dates.',
        features:[ "Earn up to 3x points cashback on every purchase, every day when you shop at select community-based merchants.",
          "Earn unlimited 1x points on every purchase, everyday",
          "$0 Annual Fee",
          "12.99% APR",
          "2% or $5 Cash Advance Fee.",
          "Foreign Transaction Fee. Up to 1%.",
          "Peace of mind from Fraud"
        ],
        image: `${visa3}`, // Replace with actual image
      link: 'https://gnbank.yourcommunitycard.com/personal',
    },
    {
      title: 'Visa Platinum Credit Card',
      description:
        'Build or rebuild your credit with a secured card. Refundable deposit required.',
        features:[ "Earn up to 3x points cashback on every purchase, every day when you shop at select community-based merchants.",
          "Earn unlimited 1x points on every purchase, everyday",
          "$0 Annual Fee",
          "10.99% APR",
          "Cash Advance Fee.",
          "Foreign Transaction Fee.",
          "Peace of mind from Fraud"
        ],
      image: `${visa2}`, // Replace with actual image
      link: 'https://gnbank.yourcommunitycard.com/personal',
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section text-center text-white py-5">
        <Container>
          <div className='bgs'>
          <p className='h2 mt-4 text-white'>Find the Right Credit Card</p>
          <p className="lead gn-secondary-title">
            Whether you’re looking for cash back, travel rewards, or to build
            credit, we’ve got a card for you.
          </p>
          <Button variant="light" size="lg" className='bg-buttons'>
            Compare All Cards
          </Button>

          </div>
        </Container>
      </section>

      {/* Cards Section */}
      <section className="cards-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4">Our Popular Cards</h2>
          <Row>
            {cards.map((card, index) => (
              <Col md={4} key={index} className="mb-4">
                <Card className="h-100 shadow-sm">
                  <Card.Img variant="top" src={card.image} alt={card.title} className='credit-card-top' />
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                    <Card.Text>{card.description} 

                    </Card.Text>
                    <ul>{card.features.map(test => (
                    <li>{test}</li>

                    ))}</ul>
                    <Button variant="primary" href={card.link} className='card-button '>
                      Learn More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section py-5">
        <Container>
          <h2 className="text-center mb-4 gn-title">Why Choose Our Credit Cards?</h2>
          <Row>
            <Col md={6}>
              <p>✔ Flexible rewards programs tailored to your needs.</p>
              <p>✔ Industry-leading fraud protection and alerts.</p>
              <p>✔ No hidden fees or surprise charges.</p>
            </Col>
            <Col md={6}>
              <p>✔ Manage your account easily with our mobile app.</p>
              <p>✔ Earn rewards that never expire.</p>
              <p>✔ Get 24/7 customer support for all your needs.</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section text-center text-white py-5 gn-darks ">
        <Container>
          <h2>Ready to Get Started?</h2>
          <p className="lead text-center">
            Apply today and start enjoying the benefits of a Capital One Credit
            Card.
          </p>
          <Button variant="light" size="lg" className=' btn  gn-buttons'>
            Apply Now
          </Button>
        </Container>
      </section>
    </div>
  );
};

export default CreditCards;
