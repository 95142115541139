import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import "./Direct.css"
const DirectDepositPage = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="hero-sections-direct text-center text-white py-5">
        <Container>
          <h1 className='gn-secondary-title'>Direct Deposit</h1>
          <p className="lead text-center">
            Get paid faster and easier with GN Bank's Direct Deposit service. Secure, convenient, and reliable.
          </p>
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 gn-title">Why Choose Direct Deposit?</h2>
          <Row>
            <Col md={4} className="mb-4">
              <div className="feature-box text-center">
                <h5 className='gn-title'>Convenience</h5>
                <p>Skip the trip to the bank—your paycheck goes straight into your account.</p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="feature-box text-center">
                <h5 className='gn-title'>Speed</h5>
                <p>Get your money faster with no delays, even during holidays.</p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="feature-box text-center">
                <h5 className='gn-title'>Security</h5>
                <p>Enjoy peace of mind knowing your funds are transferred safely.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Steps Section */}
      <section className="steps-section py-5">
        <Container>
          <h2 className="text-center mb-4 gn-title">How to Set Up Direct Deposit</h2>
          <Row>
            <Col md={6} className="mb-4">
              <h5 className='gn-title'>Step 1: Get the Form</h5>
              <p>Contact your employer or download the Direct Deposit Authorization Form from our website.</p>
            </Col>
            <Col md={6} className="mb-4">
              <h5 className='gn-title'>Step 2: Provide Information</h5>
              <p>Fill in your GN Bank account number and routing number on the form.</p>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-4">
              <h5 className='gn-title'>Step 3: Submit</h5>
              <p>Submit the completed form to your employer or benefits provider.</p>
            </Col>
            <Col md={6} className="mb-4">
              <h5 className='gn-title'>Step 4: Start Enjoying</h5>
              <p>Once processed, your funds will be deposited directly into your account.</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section text-center text-white py-5">
        <Container>
          <h2>Start Using Direct Deposit Today!</h2>
          <p className="lead text-center">
            Simplify your life and get started with Direct Deposit at GN Bank.
          </p>
          <div className='gn-button-div'>
          <Button  size="lg" className='gn-button' >
            Learn More
          </Button>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default DirectDepositPage;
