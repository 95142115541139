import React,{useState} from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {LinkContainer} from 'react-router-bootstrap'
import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MemoryRouter, Routes, Route, NavLink } from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import About from '../../screen/About/About';
import Home from '../../screen/Home/Home';

 import channel from "../../assets/images/images.png"
import Contact from '../../screen/Contact/Contact';


import LoginModal from '../LoginModal/loginModal';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faSearch, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import './Header.css'
const Mobiles = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  const handleCustomerPortal = () => {


    window.open("https://portal.gnbank.net")
  }
  

  return (

    <div className='mobile'>
 
    <Navbar expand="lg" className="bg-body-teritary">
  
    <Container>
   
   
      <Navbar.Toggle aria-controls="navbarScroll" />

  
      <Navbar.Collapse id="navbarScroll">

    
     
        <Nav


          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '300px' }}
          navbarScroll
        >
          {/* <Nav.Link to="/">Home</Nav.Link> */}
          <LinkContainer to="/">
          <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          



          <NavDropdown title="PERSONAL" id="navbarScrollingDropdown" >
          {/* <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer> */}

<LinkContainer to="/checking" className="text-success">
            <NavDropdown.Item >
              Checking
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/savings" className="text-success">
            <NavDropdown.Item >
              Savings
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/creditcards" className="text-success">
            <NavDropdown.Item>
              Credit Cards
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/cd" className="text-success">
            <NavDropdown.Item>
              CD
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/personalloans" className="text-success">
            <NavDropdown.Item>
             Personal Loans
            </NavDropdown.Item>
            </LinkContainer>



{/* <LinkContainer to="/terms">
            <NavDropdown.Item>
              TERMS
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/privacy">
            <NavDropdown.Item >
              PRIVACY
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/download">
            <NavDropdown.Item >
              Download
            </NavDropdown.Item>
            </LinkContainer> */}
           
          </NavDropdown>














          <NavDropdown title="BUSINESS" id="navbarScrollingDropdown" >
          {/* <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer> */}

<LinkContainer to="/Businesschecking" className="text-success">
            <NavDropdown.Item >
              Checking
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/Businesssavings" className="text-success">
            <NavDropdown.Item >
              Savings
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/BusinessCredit-Card" className="text-success">
            <NavDropdown.Item>
              Credit Cards
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/BusinessCd" className="text-success">
            <NavDropdown.Item>
              CD
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/BusinessLoans" className="text-success">
            <NavDropdown.Item>
            Business Loans
            </NavDropdown.Item>
            </LinkContainer>



{/* <LinkContainer to="/terms">
            <NavDropdown.Item>
              TERMS
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/privacy">
            <NavDropdown.Item >
              PRIVACY
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/download">
            <NavDropdown.Item >
              Download
            </NavDropdown.Item>
            </LinkContainer> */}
           
          </NavDropdown>






          <NavDropdown title="E-BANKING" id="navbarScrollingDropdown" >
          {/* <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer> */}

<LinkContainer to="/MobileBanking" className="text-success">
            <NavDropdown.Item >
              MOBILE BANKING
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/OnlineBanking" className="text-success">
            <NavDropdown.Item >
              ONLINE BANKING
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/EBilling" className="text-success">
            <NavDropdown.Item>
              E-BILING
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/DirectDeposit" className="text-success">
            <NavDropdown.Item>
              DIRECT DEPOSIT
            </NavDropdown.Item>
            </LinkContainer>

            {/* <LinkContainer to="/personalloans" className="text-success">
            <NavDropdown.Item>
             Personal Loans
            </NavDropdown.Item>
            </LinkContainer> */}



{/* <LinkContainer to="/terms">
            <NavDropdown.Item>
              TERMS
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/privacy">
            <NavDropdown.Item >
              PRIVACY
            </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/download">
            <NavDropdown.Item >
              Download
            </NavDropdown.Item>
            </LinkContainer> */}
           
          </NavDropdown>










         
          
          <NavDropdown title="About" id="navbarScrollingDropdown" >
          <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer>

<LinkContainer to="/contact" className="text-success">
            <NavDropdown.Item >
              CONTACT US
            </NavDropdown.Item>
            </LinkContainer>


<LinkContainer to="/privacy">
            <NavDropdown.Item>
              TERMS
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/privacy">
            <NavDropdown.Item >
              PRIVACY
            </NavDropdown.Item>
            </LinkContainer>

            {/* <LinkContainer to="/download">
            <NavDropdown.Item >
              Download
            </NavDropdown.Item>
            </LinkContainer> */}
           
          </NavDropdown>

          {/* <LinkContainer to="/news">
          <Nav.Link  className="text-primary">
            News
          </Nav.Link>
          </LinkContainer> */}

          

<div onClick={() => {handleCustomerPortal()}} className='py-2 text-dark portal-div'>

CUSTOMER PORTAL

 </div>



          {/* <LinkContainer to="/live">
          <Nav.Link  className="text-primary">
            live TV
          </Nav.Link>
          </LinkContainer> */}

         
        
         

          {/* <Link to="/live">Live</Link> */}
          {/* <Button variant="outline-primary"><Link to="/live">Live</Link></Button> */}
       
          {/* <h4 className='mt-2 text-white '><Link to="/live">WATCH</Link></h4> */}
        
         
       
        </Nav>
       

      </Navbar.Collapse>


    
      <Navbar.Brand to="/"><img src={channel}  style={{width: '50px', height: '50px' }} className="ml-5"/></Navbar.Brand>

      <span className='dt-loginmes' onClick={handleShowModal}>LOGIN</span>
         
    </Container>
   
 
  </Navbar>
 
  <LoginModal show={showModal} handleClose={handleCloseModal} />
  </div>
 
  )
}

export default Mobiles