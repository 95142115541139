import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import './SideBySideSlide.css';
import slide3 from '../../assets/images/OnlineBanking.jpg'
//import slide3 from '../../assets/img/Business.png'
import slide4 from "../../assets/img/slides/Business.png"

import bank from "../../assets/images/GNBank.png"

import online from "../../assets/images/Online.png"

import mobile from "../../assets/images/Mobile.png"
const SideBySideSlide = () => (
  <Carousel fade controls indicators={false} interval={6000}>
    <Carousel.Item>
      <div className="side-slide py-5">
        <div className="slide-inner">
          <div className="text-section mt-5">
            <div className='gn-div'></div>
            <h1 className='gn-title mt-4'>ONLINE BANKING</h1>
            <p>Explore the power of simpler and smarter banking. Bank online with over 250 services.</p>
            <Button  size="lg" className='gn-button'>Learn More</Button>
          </div>
          <div className="image-section">
            <img src={mobile} alt="Modern Banking" className="img-fluid" />
          </div>
        </div>
      </div>
    </Carousel.Item>

    <Carousel.Item>
      <div className="side-slide">
        <div className="slide-inner">
          <div className="text-section mt-5">
          <div className='gn-div'></div>
            <h1 className='gn-title mt-5 h1'>PERSONAL BANKING</h1>
            <p className=''>All Personal services at your fingertip and backed by technology.</p>
            <Button  size="lg" className='gn-button'>Get Started</Button>
          </div>
          <div className="image-section">
            <img src={online} alt="Your Money, Your Way" className="img-fluid" />
          </div>
        </div>
      </div>
    </Carousel.Item>
  </Carousel>
);

export default SideBySideSlide;
