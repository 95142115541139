import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import "./BusinessLoan.css"

import personal from "../../assets/images/loan.png"
import car from "../../assets/images/car.png"
import home from "../../assets/images/home.png"
const BusinessLoans= () => {
  const loanTypes = [
    {
      title: 'Business Lines of Credit',
      description: 'Get the funds you need for Company expenses with flexible repayment options.',
      image: `${personal}`, // Replace with a real image URL
      link: '#',
    },
    {
      title: 'Term Loans',
      description: 'Terms Loans',
      image: `${car}`, // Replace with a real image URL
      link: '#',
    },
    {
      title: 'Commercial Real Estate Financing',
      description: 'Make your dream home a reality with affordable mortgage plans.',
      image: `${home}`, // Replace with a real image URL
      link: '#',
    },
 
  ];

  return (
    <div>
      {/* Hero Section */}






<section className="hero-sections py-5 text-center bg-primary text-white">
    
        <Container className='mt-4'>
        <div className='bgs'>
          <p className='h1  gn-title '>Business Loans</p>
          <p className="lead text-dark">
          Small businesses need cash to meet their operational needs and capital investments to help them grow. GN Bank can help meet those needs with a wide range of loans and credit lines. Our Loan Officers will work with you to structure the appropriate credit facility for your business.
          </p>
          <Button variant="light" size="lg" className='bg-buttons'>
          Apply Now
          </Button>

          </div>
        </Container>
       
      </section>








      {/* <section className="hero-section text-center text-white py-5">
        <Container>
        <div className='bg py-5 card-body'>
          <h1 className='gn-title'>Business Loans</h1>
         
          <p className="lead gn-dark">
          Small businesses need cash to meet their operational needs and capital investments to help them grow. GN Bank can help meet those needs with a wide range of loans and credit lines. Our Loan Officers will work with you to structure the appropriate credit facility for your business.
          </p>
         
          <Button variant="light" size="lg" className='bg-buttons'>
            Apply Now
          </Button>
          </div>
        </Container>
      </section> */}

      {/* Loan Types Section */}
      <section className="loan-types-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4">Explore Our Loan Options</h2>
          <Row>
            {loanTypes.map((loan, index) => (
              <Col md={6} lg={4} className="mb-4" key={index}>
                <Card className="h-100 shadow-sm">
                  <Card.Img variant="top" src={loan.image} alt={loan.title} />
                  <Card.Body>
                    <Card.Title className='gn-secondary-title'>{loan.title}</Card.Title>
                    <Card.Text>{loan.description}</Card.Text>
                    <Button variant="primary" className='bg-buttons' href={loan.link}>
                      Learn More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta-section text-center text-white py-5">
        <Container>
          <h2>Ready to Take the Next Step?</h2>
          <p className="lead text-center">
            Apply for a loan today and let GN Bank help you achieve your goals.
          </p>
          {/* <Button variant="light" size="lg">
            Get Started
          </Button> */}

           <div className='gn-button-div'>
                    <Button  size="lg" className='gn-button'>
                     Get Started
                    </Button>
                    </div>
        </Container>
      </section>
    </div>
  );
};

export default BusinessLoans;

