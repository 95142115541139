import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import "./Loan.css"

import personal from "../../assets/images/loan.png"
import car from "../../assets/images/car.png"
import home from "../../assets/images/home.png"
const Loans= () => {
  const loanTypes = [
    {
      title: 'Personal Loans',
      description: 'Get the funds you need for personal expenses with flexible repayment options.',
      image: `${personal}`, // Replace with a real image URL
      link: '#',
    },
    {
      title: 'Auto Loans',
      description: 'Finance your dream car with competitive rates and quick approvals.',
      image: `${car}`, // Replace with a real image URL
      link: '#',
    },
    {
      title: 'Home Loans',
      description: 'Make your dream home a reality with affordable mortgage plans.',
      image: `${home}`, // Replace with a real image URL
      link: '#',
    },
 
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section text-center text-white py-5">
        <Container>
        <div className='bg py-5 card-body'>
          <h1 className='gn-title'>Personal Loans</h1>
         
          <p className="lead gn-dark">
          In addition to our residential mortgage loans, we offer several other loan products to meet your individual credit needs, including
          </p>
         
          <Button variant="light" size="lg" className='bg-buttons'>
            Apply Now
          </Button>
          </div>
        </Container>
      </section>

      {/* Loan Types Section */}
      <section className="loan-types-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4">Explore Our Loan Options</h2>
          <Row>
            {loanTypes.map((loan, index) => (
              <Col md={6} lg={4} className="mb-4" key={index}>
                <Card className="h-100 shadow-sm">
                  <Card.Img variant="top" src={loan.image} alt={loan.title} />
                  <Card.Body>
                    <Card.Title className='gn-secondary-title'>{loan.title}</Card.Title>
                    <Card.Text>{loan.description}</Card.Text>
                    <Button variant="primary" className='bg-buttons' href={loan.link}>
                      Learn More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta-section text-center text-white py-5">
        <Container>
          <h2>Ready to Take the Next Step?</h2>
          <p className="lead text-center">
            Apply for a loan today and let GN Bank help you achieve your goals.
          </p>
          <div className='gn-button-div'>
          <Button  size="lg" className='gn-button'>
            Get Started
          </Button>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Loans;

